<template>
    <v-container>
        <PageHeader :title="title" :items="items" />

        <loading-flux :value="loadingActivarDesactivarUsuario || loading"></loading-flux>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-alert
                            :show="segundosMensajeActualizacion"
                            dismissible
                            :variant="mensaje.variant"
                            @dismissed="segundosMensajeActualizacion = 0"
                            @dismiss-count-down="
                                actualizarSegundosMensajeActualizacion
                            "
                        >
                            {{ mensaje.texto }}
                        </b-alert>
                        <div class="d-flex">
                            <div class="card-title col-auto me-auto">
                                Usuarios
                            </div>
                            <button
                                v-if="rolModuloPermiso.escritura"
                                class="btn btn-primary col-auto"
                                @click="abrirModalNuevo"
                            >
                                <i class="mdi mdi-plus me-1"></i>
                                Nuevo
                            </button>
                        </div>
                        <div class="d-flex"></div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_length"
                                    class="dataTables_length"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Mostrar&nbsp;
                                        <b-form-select
                                            class="form-select form-select-sm"
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Buscar:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Buscar..."
                                            class="
                                                form-control
                                                form-control-sm
                                                ms-2
                                            "
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                class="datatables tabla-personalizada"
                                :items="usuarios"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                select-mode="single"
                                selectable
                                @filtered="onFiltered"
                                :busy="usuariosCargando"
                            >
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner
                                            class="align-middle"
                                        ></b-spinner>
                                    </div>
                                </template>

                                <template #cell(imagen_perfil)="data">
                                    <div
                                        v-if="!data.item.imagen_perfil"
                                        class="avatar-xs"
                                    >
                                        <span
                                            class="avatar-title rounded-circle"
                                            >{{
                                                data.item.nombre_usuario != null
                                                    ? data.item.nombre_usuario.charAt(
                                                          0
                                                      )
                                                    : "U"
                                            }}</span
                                        >
                                    </div>
                                    <div v-if="data.item.imagen_perfil">
                                        <img
                                            class="rounded-circle avatar-xs"
                                            :src="urlImagenUsuario(data.item.imagen_perfil)"
                                            alt
                                        />
                                    </div>
                                </template>

                                <template #cell(activo)="data" v-if="rolModuloPermiso.escritura">
                                    <div
                                        class="form-check form-switch form-switch-md mb-3"
                                    >
                                        <input
                                            @click="
                                                activarDesactivar(
                                                    data.item
                                                )
                                            "
                                            class="form-check-input"
                                            type="checkbox"
                                            :checked="data.item.activo"
                                        />
                                    </div>
                                </template>

                                <template #cell(debe_cambiar_clave)="data" v-if="rolModuloPermiso.escritura">
                                    <div
                                        class="form-check form-switch form-switch-md mb-3"
                                    >
                                        <input
                                            @click="
                                                cambiarClave(
                                                    data.item
                                                )
                                            "
                                            class="form-check-input"
                                            type="checkbox"
                                            :checked="data.item.debe_cambiar_clave"
                                        />
                                    </div>
                                </template>

                                <template #cell(nombre_usuario)="data">
                                    <h5 class="font-size-14 mb-1">
                                        <span class="text-dark">{{
                                            data.item.nombre_usuario
                                        }}</span>
                                    </h5>
                                    <p class="text-muted mb-0">
                                        {{ data.item.nombre_cargo }}
                                    </p>
                                </template>
                                <template #cell(rut_usuario)="data">
                                    <span v-if="data.item.rut_usuario != null && data.item.rut_usuario != ''" class="">{{data.item.rut_usuario}}-{{data.item.rut_usuario_dv}}</span>
                                </template>
                                <template #cell(rut_empresa)="data">
                                    <span v-if="data.item.rut_empresa != null && data.item.rut_empresa != ''" class="">{{data.item.rut_empresa}}-{{data.item.rut_empresa_dv}}</span>
                                </template>
                                <template #cell(accion)="data" v-if="rolModuloPermiso.escritura">
                                    <i
                                        class="
                                            bx bx-edit
                                            h3
                                            text-primary
                                            pointer
                                            me-2
                                        "
                                        @click="abrirModalEditar(data.item)"
                                    ></i>
                                    <i
                                        class="
                                            bx bx-trash
                                            h3
                                            text-primary
                                            pointer
                                        "
                                        @click="eliminarUsuarioLocal(data.item)"
                                    ></i>
                                </template>
                                <template #cell(tipo_usuario)="data">
                                    <span
                                        :class="color(data.item.tipo_usuario)"
                                        class="badge font-size-13"
                                        >{{ data.item.tipo_usuario }}</span
                                    >
                                </template>
                                <!-- <template #cell(nombre_cargo)="data">
                                    <span
                                        class="badge badge-soft-primary font-size-13"
                                        >{{ data.item.nombre_cargo}}</span
                                    >
                                </template> -->
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                    class="
                                        dataTables_paginate
                                        paging_simple_numbers
                                        float-end
                                    "
                                >
                                    <ul
                                        class="
                                            pagination pagination-rounded
                                            mb-0
                                        "
                                    >
                                        <!-- pagination -->
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
            v-model="mostrarModal"
            title="Agregar Usuario"
            hide-footer
            size="lg"
        >
            
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="nombreUsuario">Nombre Usuario</label>
                            <input
                                id="nombreUsuario"
                                v-model="$v.form.nombreUsuario.$model"
                                type="text"
                                class="form-control"
                                placeholder="Nombre Usuario"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.form.nombreUsuario.$error,
                                }"
                            />
                            <div
                                v-if="
                                    submitted && !$v.form.nombreUsuario.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="codigoUsuario">Codigo Usuario</label>
                            <input
                                id="codigoUsuario"
                                v-model="$v.form.codigoUsuario.$model"
                                type="text"
                                class="form-control"
                                placeholder="Codigo Usuario"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.form.codigoUsuario.$error,
                                }"
                            />
                            <div
                                v-if="
                                    submitted && !$v.form.codigoUsuario.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3 row">
                            <label for="rutUsuario">Rut</label>
                            <div class="col-10">
                                <input
                                    id="rutUsuario"
                                    v-model="$v.form.rutUsuario.$model"
                                    type="number"
                                    class="form-control"
                                    placeholder="Rut"
                                    v-on:input="
                                        calcularDvRutUsuario(
                                            $v.form.rutUsuario.$model
                                        )
                                    "
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.form.rutUsuario.$error,
                                    }"
                                />
                            </div>
                            <div class="col-2">
                                <input
                                    id="rutUsuarioDv"
                                    v-model="$v.form.rutUsuarioDv.$model"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.form.rutUsuarioDv.$error,
                                    }"
                                />
                            </div>
                            <div
                                v-if="submitted && !$v.form.rutUsuario.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="email">Email</label>
                            <input
                                id="email"
                                v-model="$v.form.email.$model"
                                type="email"
                                class="form-control"
                                placeholder="Email"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.email.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.email.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3 row">
                            <label for="rutEmpresa">Rut Empresa</label>
                            <div class="col-10">
                                <input
                                    id="rutEmpresa"
                                    v-model="$v.form.rutEmpresa.$model"
                                    type="number"
                                    class="form-control"
                                    placeholder="Rut"
                                    v-on:input="
                                        calcularDvRutEmpresa(
                                            $v.form.rutEmpresa.$model
                                        )
                                    "
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.form.rutEmpresa.$error,
                                    }"
                                />
                            </div>

                            <div class="col-2">
                                <input
                                    id="rutEmpresaDv"
                                    v-model="$v.form.rutEmpresaDv.$model"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.form.rutEmpresaDv.$error,
                                    }"
                                />
                            </div>

                            <div
                                v-if="submitted && !$v.form.rutEmpresa.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="nombreEmpresa">Nombre empresa</label>
                            <input
                                id="nombreEmpresa"
                                v-model="$v.form.nombreEmpresa.$model"
                                type="text"
                                class="form-control"
                                placeholder="Nombre empresa"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.form.nombreEmpresa.$error,
                                }"
                            />
                            <div
                                v-if="
                                    submitted && !$v.form.nombreEmpresa.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="tipoUsuario">Tipo Usuario</label>
                            <multiselect
                                v-model="$v.form.tipoUsuario.$model"
                                :options="tipoUsuarios"
                                placeholder="Seleccionar Tipo Usuario"
                                value="tipo_usuario_id"
                                label="nombre_tipo_usuario"
                                :searchable="true"
                                :loading="tipoUsuariosCargando"
                                open-direction="bottom"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.tipoUsuario.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="
                                    submitted && !$v.form.tipoUsuario.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="cargo">Cargo</label>
                            <multiselect
                                v-model="$v.form.cargo.$model"
                                :options="cargos"
                                placeholder="Seleccionar Cargo"
                                value="cargo_id"
                                label="nombre_cargo"
                                :searchable="true"
                                :loading="cargosCargando"
                                open-direction="bottom"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.cargo.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="submitted && !$v.form.cargo.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="rol">Rol</label>
                            <multiselect
                                v-model="$v.form.rol.$model"
                                :options="roles"
                                placeholder="Seleccionar Rol"
                                value="codigo_rol"
                                label="nombre_rol"
                                :searchable="true"
                                :loading="rolesCargando"
                                open-direction="bottom"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.rol.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="submitted && !$v.form.rol.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="fono1">Fono 1</label>
                            <input
                                id="fono1"
                                v-model="$v.form.fono1.$model"
                                type="text"
                                class="form-control"
                                placeholder="Fono 1"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.fono1.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.fono1.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="fono2">Fono 2</label>
                            <input
                                id="fono2"
                                v-model="$v.form.fono2.$model"
                                type="text"
                                class="form-control"
                                placeholder="Fono 2"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.fono2.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.fono2.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="imagenPerfil">Imagen Perfil</label>
                            <input
                                class="form-control"
                                type="file"
                                name="imagenPerfil"
                                accept=".png, .jpg, .jpeg,.bmp"
                                @change="onFileChange($event)"
                            />
                        </div>
                    </div>
                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModal = false"
                        >Cerrar</b-button
                    >
                    <b-button v-if="rolModuloPermiso.escritura" type="submit" variant="success" class="ms-1"
                        >Guardar</b-button
                    >
                </div>
            </form>
        </b-modal>
    </v-container>
</template>
<script>
// import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, numeric } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { usuarioMethods, usuarioComputed } from "@/state/helpers";
import { rolMethods, rolComputed } from "@/state/helpers";
import { cargoMethods, cargoComputed } from "@/state/helpers";
import {authMethods,authUsuarioComputed} from "@/state/helpers";
import {permiso} from "@/helpers/authservice/obtener-permisos";
import Swal from "sweetalert2";
import { calculateDV } from "@/assets/js/rut.js";
import Multiselect from "vue-multiselect";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    page: {
        title: "Usuarios",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        // Layout,
        PageHeader,
        Multiselect,
    },

    data() {
        return {
            loading: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            title: "Usuarios",
            items: [
                {
                    text: "Usuarios",
                },
                {
                    text: "Usuarios",
                    active: true,
                },
            ],
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "codigo",
            sortDesc: false,
            fields: [
                {
                    key: "Accion",
                    label: "Acción",
                    sortable: false,
                },
                {
                    key: "activo",
                    sortable: true,
                },
                {
                    key: "debe_cambiar_clave",
                    label:'Cambiar Clave',
                    sortable: true,
                },
                {
                    key: "imagen_perfil",
                    label: "Imagen",
                    sortable: true,
                },
                {
                    key: "nombre_usuario",
                    label: "Nombre",
                    sortable: true,
                    thStyle: { width: "500px !important" },
                },
                {
                    key: "codigo_usuario",
                    label: "Código",
                },
                {
                    key: "rut_usuario",
                    label: "Rut",
                    sortable: true,
                },
                {
                    key: "email",
                    sortable: true,
                },
                {
                    key: "rut_empresa",
                    sortable: true,
                },
                {
                    key: "nombre_empresa",
                    sortable: true,
                },
                {
                    key: "tipo_usuario",
                    sortable: true,
                },
                {
                    key: "nombre_cargo",
                    sortable: true,
                },
                {
                    key: "nombre_rol",
                    sortable: true,
                }
               
            ],
            usuarioSeleccionado: {},
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            usuarios: [],
            usuariosCargando: false,
            roles: [],
            rolesCargando: false,
            form: {
                nombreUsuario: null,
                codigoUsuario: null,
                rutUsuario: null,
                rutUsuarioDv: null,
                rutEmpresa: null,
                rutEmpresaDv: null,
                nombreEmpresa: null,
                email: null,
                tipoUsuario: null,
                cargo: null,
                rol: null,
                fono1: null,
                fono2: null,
            },
            imagenPerfil: null,
            tipoUsuarios: [
                {
                    tipo_usuario_id: "FLUXSOLAR",
                    nombre_tipo_usuario: "FLUXSOLAR",
                },
                { tipo_usuario_id: "CLIENTE", nombre_tipo_usuario: "CLIENTE" },
                { tipo_usuario_id: "VISITA", nombre_tipo_usuario: "VISITA" },
            ],
            tipoUsuariosCargando: false,
            cargos: [],
            cargosCargando: false,
            loadingActivarDesactivarUsuario:false,
            rolModuloPermiso:{}
        };
    },
    validations: {
        form: {
            nombreUsuario: { required },
            codigoUsuario: { required },
            rutUsuario: { numeric },
            rutUsuarioDv: {},
            rutEmpresa: { numeric },
            rutEmpresaDv: {},
            nombreEmpresa: {},
            email: {required},
            tipoUsuario: {required},
            cargo: {required},
            rol: {required},
            fono1: {},
            fono2: {},
        },
    },
    mounted() {
        this.obtenerUsuariosLocal();
        let permisos = permiso.obtenerPermisos(this.user,this.$router.currentRoute.name)
        this.rolModuloPermiso = permisos
    },
    methods: {
        ...usuarioMethods,
        ...rolMethods,
        ...cargoMethods,
        cambiarClave(item){
            this.loadingActivarDesactivarUsuario = true
            this.debeCambiarClave({token_usuario:item.token_usuario})
                .then((res) => {
                    if (res.status == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Usuario actualizado correctamente!!!";
                        this.mostrarModal = false;
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    // this.obtenerUsuariosLocal();
                    this.loadingActivarDesactivarUsuario = false
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.mostrarModal = false;
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loadingActivarDesactivarUsuario = false
                });
        
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        abrirModalNuevo() {
            this.resetForm();
            this.obtenerRolesLocal();
            this.obtenerCargosLocal();
            this.esEditar = false;
            this.submitted = false;
            this.mostrarModal = true;
        },
        abrirModalEditar(datos) {
            this.usuarioSeleccionado = datos;
            this.resetForm();
            this.obtenerRolesLocal();
            this.obtenerCargosLocal();
            this.$v.form.nombreUsuario.$model = datos.nombre_usuario;
            this.$v.form.codigoUsuario.$model = datos.codigo_usuario;
            this.$v.form.rutUsuario.$model = datos.rut_usuario;
            this.$v.form.rutUsuarioDv.$model = datos.rut_usuario_dv;
            this.$v.form.rutEmpresa.$model = datos.rut_empresa;
            this.$v.form.rutEmpresaDv.$model = datos.rut_empresa_dv;
            this.$v.form.nombreEmpresa.$model = datos.nombre_empresa;
            this.$v.form.email.$model = datos.email;
            this.$v.form.tipoUsuario.$model = {
                tipo_usuario_id: datos.tipo_usuario,
                nombre_tipo_usuario: datos.tipo_usuario,
            };
            this.$v.form.fono1.$model = datos.fono1;
            this.$v.form.fono2.$model = datos.fono2;

            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },
        urlImagenUsuario(imagen_perfil){
            return `${process.env.VUE_APP_URL_IMAGENES_USUARIOS}${imagen_perfil}`
        },
        obtenerUsuariosLocal() {
            this.usuariosCargando = true;
            this.obtenerUsuarios()
                .then((res) => {
                    this.usuarios = res.body;
                    this.totalRows = this.usuarios.length
                    this.usuariosCargando = false;
                })
                .catch((error) => {
                    this.usuariosCargando = false;
                });
        },
        obtenerRolesLocal() {
            this.rolesCargando = true;
            this.obtenerRolesActivos()
                .then((res) => {
                    this.roles = res.body;
                    if(this.usuarioSeleccionado.codigo_rol != undefined){
                        this.$v.form.rol.$model = {
                            codigo_rol: this.usuarioSeleccionado.codigo_rol,
                            nombre_rol: this.usuarioSeleccionado.nombre_rol,
                        };
                    }
    
                    
                    this.rolesCargando = false;
                })
                .catch((error) => {
                    this.rolesCargando = false;
                });
        },
        obtenerCargosLocal() {
            this.cargosCargando = true;
            this.obtenerCargos()
                .then((res) => {
                    this.cargos = res.body;
                    if(this.usuarioSeleccionado.cargo_id != undefined){
                        this.$v.form.cargo.$model = {
                            cargo_id: this.usuarioSeleccionado.cargo_id,
                            nombre_cargo: this.usuarioSeleccionado.nombre_cargo,
                        };
                    }
                   
                    this.cargosCargando = false;
                })
                .catch((error) => {
                    this.cargosCargando = false;
                });
        },
        activarDesactivar(item) {
            this.loadingActivarDesactivarUsuario = true
            this.activarDesactivarUsuario({token_usuario:item.token_usuario})
                .then((res) => {
                    if (res.status == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Usuario actualizado correctamente!!!";
                        this.mostrarModal = false;
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    // this.obtenerUsuariosLocal();
                    this.loadingActivarDesactivarUsuario = false
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.mostrarModal = false;
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loadingActivarDesactivarUsuario = false
                });
        },
        submit(e) {
            this.loading = true;
            this.submitted = true;
            this.$v.form.$touch();

            if (this.$v.form.$invalid) {
                this.loading = false;
                return;
            }

            let token_usuario = "";
            if (this.esEditar) {
                token_usuario = this.usuarioSeleccionado.token_usuario;
            }

            const nombre_usuario = this.$v.form.nombreUsuario.$model;
            const codigo_usuario = this.$v.form.codigoUsuario.$model;
            const rut_usuario = parseInt(this.$v.form.rutUsuario.$model);
            const rut_usuario_dv = this.$v.form.rutUsuarioDv.$model;
            const rut_empresa = this.$v.form.rutEmpresa.$model;
            const rut_empresa_dv = this.$v.form.rutEmpresaDv.$model;
            const nombre_empresa = this.$v.form.nombreEmpresa.$model;
            const email = this.$v.form.email.$model;
            const tipo_usuario = this.$v.form.tipoUsuario.$model
                .tipo_usuario_id;
            const cargo_id = this.$v.form.cargo.$model != null ? this.$v.form.cargo.$model.cargo_id : null;
            const codigo_rol = this.$v.form.rol.$model != null ? this.$v.form.rol.$model.codigo_rol : null;
            const fono1 = this.$v.form.fono1.$model != null ? this.$v.form.fono1.$model : null;
            const fono2 = this.$v.form.fono2.$model != null ? this.$v.form.fono2.$model : null;

            let formData = new FormData();
            formData.append("file", this.imagenPerfil);

            formData.append(
                "usuario",
                JSON.stringify({
                    token_usuario,
                    nombre_usuario,
                    codigo_usuario,
                    rut_usuario,
                    rut_usuario_dv,
                    rut_empresa,
                    rut_empresa_dv,
                    nombre_empresa,
                    email,
                    tipo_usuario,
                    cargo_id,
                    codigo_rol,
                    fono1,
                    fono2
                })
            );

            if (this.esEditar) {
                this.actualizarUsuarioForm(formData)
                    .then((res) => {
                        if (res.status == 202) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto =
                                "Ha ocurrido un error, intente nuevamente";
                        }

                        if (res.status == 204) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Usuario actualizado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerUsuariosLocal();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                        this.mostrarModal = false;
                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    });
            } else {
                this.crearUsuarioForm(formData)
                    .then((res) => {
                        if (res.status == 201) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Usuario creado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        if (res.status == 208) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto = "El usuario ya existe!!!";
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerUsuariosLocal();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";

                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    });
            }

            this.submitted = false;
        },
        resetForm() {
            this.form = {
                nombreUsuario: null,
                codigoUsuario: null,
                rutUsuario: null,
                rutUsuarioDv: null,
                rutEmpresa: null,
                rutEmpresaDv: null,
                nombreEmpresa: null,
                email: null,
                tipoUsuario: null,
                cargo: null,
                rol: null,
                fono1: null,
                fono2: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        eliminarUsuarioLocal(item) {
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-danger ms-2",
                    cancelButton: "btn btn-gris-claro",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                    reverseButtons: true
                })
                .then((result) => {
                    if (result.value) {
                        this.loading = true;
                        this.eliminarUsuario(item.token_usuario)
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Usuario eliminado correctamente!!!";
                                    this.mostrarModal = false;
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                                this.obtenerUsuariosLocal();
                                this.loading = false;
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.loading = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        onFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();
                this.imagenPerfil = file;
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        color(item) {
            if (item != null) {
                if (item == 1) {
                    return "bg-success";
                } else if (item == 2) {
                    return "bg-warning";
                } else if (item.toLowerCase() == "fluxsolar") {
                    return "bg-success";
                } else if (item.toLowerCase() == "cliente") {
                    return "bg-primary";
                } else if (item.toLowerCase() == "visita") {
                    return "bg-warning";
                }
            }
        },
        calcularDvRutUsuario: function(rut) {
            this.$v.form.rutUsuarioDv.$model = calculateDV(rut);
        },
        calcularDvRutEmpresa: function(rut) {
            this.$v.form.rutEmpresaDv.$model = calculateDV(rut);
        },
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        todoFiltered(filteredItems) {
            this.todoTotalRows = filteredItems.length;
            this.todocurrentPage = 1;
        },

        handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        },
    },
    computed: {
        ...authUsuarioComputed,
        // ...usuarioComputed,
        rows() {
            return 1;
        },

        /**
         * Todo list of records
         */
        todoList() {
            return this.todos.length;
        },
    },
};
</script>
